.mainWrapper{
    height: 100vh;
    overflow-y: auto;
}

.signInWrapper {
    display: grid;
    grid-template-columns: auto 25vw;

    height: 100vh;
    overflow-y: hidden;
}

.signUpNavWrapper {
    display: block;
    background-color: var(--text);
    width: 100%;
    height: 100vh;

    overflow: hidden;
}

.signUpNavContent {
    padding: 5rem 0;
    width: 80%;
    margin: auto;
}

.signUpNavButton {
    width: 60%;
}

.h1Inverted {
    color: var(--background);
    margin-bottom: 2.5rem;
}


@media screen and (max-width: 800px) {
    .signInWrapper {
        grid-template-columns: auto;
        overflow-y: auto;
    }

    .signUpNavWrapper {
        display: none;
    }

}