.maxWidth{
    width: 100%;
    max-width: 600px;
    min-width: 250px;

    padding: 0 1rem;

    margin: auto;
}


.cityGrid{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    row-gap: 1rem;
    column-gap: 0.8rem;
}

.formGrid{
    display: grid;
    row-gap: 0.5rem;
}

.organizationTypeGrid{
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 1rem;
    width: 100%;
}

.organizationTypeGrid > * {    
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 4px;
    width: fit-content;
    margin: 0.5rem 0rem;

    cursor: pointer;
}

.organizationTypeGrid > * > * {    
    cursor: pointer;
}

.dpaWarningWrapper{
    margin-top: 2rem;
    width: 100%;
    padding: 1rem;
    background-color: var(--primary-background);
    border: 1px solid var(--primary);
    border-radius: 1rem;

    display: grid;
    grid-template-columns: auto;
    row-gap: 0.5rem;
}

.warningWrapper{
    padding: 0.5rem 1rem;
    border: orange 1px solid;
    background-color: rgb(255, 243, 216);
    border-radius: 5px;
}

@media screen and (max-width: 650px) {
    .organizationTypeGrid{
        grid-template-columns: auto;
    }
}