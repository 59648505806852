.card{
    min-width: 20rem;
    max-width: 25rem;
    margin: auto;
    background-color: var(--background-2);
    border-radius: 1rem;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(180, 180, 180, 0.19); */

    padding: 2rem;

    margin-top: 5rem;
    margin-bottom: 5rem;
}

.buttonGrid{
    display: flex;
    -webkit-display: flex;
    column-gap: 0.5rem;

    width: -moz-fit-content;
    width: fit-content;
}

.buttonGridFilled{
    display: flex;
    -webkit-display: flex;
    column-gap: 0.5rem;

    width: 100%
}

.buttonGridFilled > * {
    flex-grow: 1;
}

.textfieldInput{
    outline: none;
    border: none;
    font-family: 'Poppins', 'serif';
    font-size: 0.8rem;
    font-weight: var(--weight-normal);
    color: var(--text);
    width: 100%;

    padding: 0.8rem 0.6rem;
    border-radius: 0.4rem;
    border: solid 1px var(--border);
    background-color: var(--background);
    box-sizing: border-box;
}

.textfieldInput:focus{
    border: solid 1px var(--border-active);
}

.label{
    -webkit-user-select: none;
    user-select: none;
    font-family: 'Poppins', 'serif';
    font-size: 0.7rem;
    font-weight: var(--weight-heavy);
    color: var(--text-off);
}

label{
    font-size: 14px;
}

button{
    transition: 0.15s, smooth;
}


p{
    font-size: 16px;
    font-weight: var(--weight-normal);
    margin: 0.3rem 0 0.3rem 0;
    color: var(--text);
}

h1{
    font-family: "Inter","acumin-pro", sans-serif;
    font-weight: var(--weight-heavy);
    font-size: 2.6rem;
}

h2{
    font-family: "Inter","acumin-pro", sans-serif;
    font-weight: var(--weight-heavy);
    font-size: 2rem;
}

h3{
    font-family: "Inter","acumin-pro", sans-serif;
    font-weight: var(--weight-heavy);
    font-size: 1.4rem;

    color: var(--text);
    text-decoration: none;
}

h4{
    font-family: "Inter", "acumin-pro", sans-serif;
    font-weight: var(--weight-heavy);
    font-size: 1.2rem;

    color: var(--text);
    text-decoration: none;
}

.input {
    border-radius: 0.2rem;
    border: 1px solid var(--border);
    outline: none;
    width: 100%;

    font-family: 'Segoe UI', system-ui, sans-serif;
    font-size: 16px;
    padding: 0.7rem;

    transition: 0.2s, smooth;
}

.input:focus {
    border: 1px solid var(--primary);
}


.textarea {
    border-radius: 0.2rem;
    border-color: var(--border);
    height: auto;
    max-height: 55vh;
    min-height: 45vh;
    outline: none;

    width: 100%;
    max-width: var(--max-view-width);
    resize: none;
    overflow-y: scroll;

    font-family: 'Segoe UI', system-ui, sans-serif;
    font-size: 16px;
    padding: 0.7rem;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    transition: 0.2s, smooth;
}

.textarea:focus {
    border-color: var(--primary);
}

.center{
    text-align: center;
}

.left{
    text-align: left;
}

.ps{
    font-size: 12px;
    font-weight: var(--p-weight);
    margin: 0.3rem 0 0.3rem 0;
    color: var(--text-off2);
}

@media screen and (max-width: 800px) {
    h1{
        font-size: 36px;
    }
    
    h2{
        font-size: 28px;
    }
    
    h3{
        font-size: 22px;
    }
}