.wrapper {
    display: grid;
    grid-template-columns: auto 22rem;
    column-gap: 0rem;
    margin-bottom: 1rem;
    row-gap: 0.5rem;

    min-width: 952px;
}

.guide{
    border: 1px solid var(--primary);
    background-color: var(--primary-background);
    border-radius: 5px;
    
    padding: 1rem;
    margin-bottom: 1rem;
    width: 100%;

    display: grid;
    grid-template-columns: auto 6rem;
    column-gap: 1rem;
    align-items: center;
}

.text {
    width: 100%;
    max-width: 600px;
    margin: 0;
    margin-top: 0.2rem;

    font-family: Segoe UI;

    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

.sideWrapper {
    position: relative;
}

.addCommentWrapper {
    position: absolute;
    left: 0;
    top: 5rem;

    z-index: 1100;
}

.commentListWrapper {
    position: absolute;
}

.commentList {
    margin-left: 3rem;
    position: relative;
}

.comment {
    position: absolute;

    padding: 0.5rem 1rem;
    width: 19rem;
    min-height: 50px;

    display: grid;
    grid-template-columns: 15rem 2rem;
    column-gap: 0.5rem;
    align-items: baseline;

    cursor: default;
    box-shadow: none;
    background-color: white;

    font-size: 15px;

    border: 1px solid var(--border);
    border-radius: 0 15px 15px 15px;

    transition: 0.2s, smooth;
}

.commentFocused {
    position: absolute;

    padding: 0.5rem 1rem;
    width: 19rem;

    display: grid;
    grid-template-columns: 15rem 2rem;
    column-gap: 0.5rem;
    align-items: baseline;

    cursor: default;
    box-shadow: 0 1px 10px 2px #00000015;
    background-color: white;

    font-size: 15px;

    border: 1px solid var(--primary);
    border-radius: 0 15px 15px 15px;

    transition: 0.2s, smooth;
}

.mark {
    background-color: rgb(250, 250, 137);
    color: var(--text);
    transition: 0.2s, smooth;
    user-select: none;
}

.markFocused {
    background-color: rgb(250, 197, 137);
    color: var(--text);
    transition: 0.2s, smooth;
    user-select: none;
}

.markBlue {
    background-color: var(--primary);
    color: white;
}


/*Option icon*/
.addIconButton {
    width: 3rem;
    height: 3rem;

    outline: none;
    border: none;

    background-color: #f9fdff;
    border-radius: 50%;
    box-shadow: 0 1px 10px 2px #00000050;

    cursor: pointer;
    text-align: center;
    vertical-align: middle;

    transition: 0.1s, smooth;
}

.addIconButton:hover,
.addIconButton:focus {
    background-color: var(--primary-background);
}

.addIcon {
    height: 24px;
    width: 24px;
    margin: auto;
}

/** Comment Options **/


.optionWrapper {
    display: block;

    position: relative;
    width: fit-content;
    height: fit-content;
}

.optionIconButton {
    width: 2rem;
    height: 2rem;

    outline: none;
    border: none;

    background-color: #00000000;
    border-radius: 50%;

    cursor: pointer;
    text-align: center;
    vertical-align: middle;

    transition: 0.2s, smooth;
}

.optionIconButton:hover,
.optionIconButton:focus {
    background-color: var(--primary-background);
}

.optionIcon {
    width: auto;
    height: 0.8rem;
}

.optionMenu {
    position: absolute;
    top: 0.5rem;
    right: 0;

    width: fit-content;
    min-width: 8rem;

    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: 5px;

    display: grid;
    grid-template-columns: auto;

    z-index: 100;

    overflow: hidden;
}

.optionMenuItem {
    padding: 0.5rem 1rem;
    border: none;
    border-bottom: 1px solid var(--border);

    text-align: left;
    background-color: var(--background);

    outline: none;
    transition: 0.2s, smooth;
    cursor: pointer;

    font-size: 14px;
}

.optionMenuItem:hover,
.optionMenuItem:focus {
    background-color: var(--background-hover);
}