.wrapper{
    max-width: 800px;
    width: fit-content;
}


.paragraphWrapperAI {
    display: grid;
    grid-template-columns: auto 20rem;
    column-gap: 2rem;
    margin-bottom: 1rem;
    row-gap: 0.5rem;

    width: 100%;

    position: relative;
}

.resultBox {
    display: block;
    /* padding: 0.2rem 0; */
    margin-left: auto;
    user-select: none;

    background-color: var(--background);
    position: absolute;
    width: 20rem;
    top: -1px;
    right: 0;

    height: 100%;
}

.resultBoxMobile {
    display: none;
    margin: auto;
    user-select: none;
    margin-top: 1rem;
}

.text {
    width: 100%;
    max-width: 600px;
    margin: 0;
    margin-top: 0.2rem;

    font-family: Segoe UI;

    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

.mark {
    background-color: rgb(253, 218, 218);
    color: var(--text);
}

.selectWrapper{
    width: 15rem;
}


@media screen and (max-width: 800px) {
    .wrapper{
        max-width: 90vw;
        width: fit-content;
    }

    .text {
        width: 90vw;
    }

    .paragraphWrapperAI {
        grid-template-columns: auto;
        row-gap: 0rem;
        column-gap: 0rem;
        border-top: none;
        margin-bottom: 1rem;
    }

    .resultBox {
        display: none;
    }

    .resultBoxMobile {
        display: block;
    }
}


@media screen and (max-width: 450px) {
    .selectWrapper{
        width: 100%;
    }
}
