.signInWrapper {
    display: grid;
    grid-template-columns: auto 25vw;

    height: 100vh;
    overflow-y: hidden;
}

.contentWrapper {
    margin: auto;

    display: flex;
    -webkit-display: flex;
    flex-direction: column;

    width: 20vw;
    min-width: 18rem;
    max-width: 24rem;

    text-align: center;
}

.actionGroup {
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    column-gap: 2rem;
}

.actionItem {
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 0.5rem;
    margin: auto;
}


.contentGroup {
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.signInGroup {
    width: 100%;

    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    padding-bottom: 3rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid var(--border);
}

.input {
    border-radius: 0.2rem;
    border: 1px solid var(--border);
    outline: none;

    width: calc(100% - 2rem - 2px);
    max-width: var(--max-view-width);
    resize: none;
    overflow-y: scroll;

    font-family: 'Poppins' serif;
    font-size: 16px;
    padding: 1rem;
}

.input:focus {
    border: 1px solid var(--text);

}

.signInSubmit {
    margin: auto;
    margin-top: 0.5rem;
    width: 50%;
    min-width: 14rem;
}

.errorMessage {
    color: var(--error);
    margin-bottom: 1rem;
}

.privacySubmitWrapper{
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 2rem auto;
    text-align: left;
    align-items: start;
}

.privacySubmitWrapper > input {
    margin-top: 0.4rem;
}

.privacySubmitWrapper > label {
    user-select: none;
}

.privacySubmitWrapper > label > a{
    font-weight: var(--weight-normal);
    color: var(--link);
    text-decoration: underline;
}

.link {
    display: none;
    font-weight: var(--weight-normal);
    color: var(--link);
    text-decoration: underline;
}

@media screen and (max-width: 800px) {
    .actionGroup {
        display: flex;
        -webkit-display: flex;
        flex-direction: column;
        column-gap: 1rem;
        row-gap: 1rem;
    }

    .actionItem {
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        column-gap: 0.5rem;
    }

    .contentGroup {
        margin-top: 2rem;
    }

    .signInWrapper {
        grid-template-columns: auto;
        overflow-y: auto;
    }

    .link{
        display: block;
    }

    .signInGroup {
        padding-top: 1.5rem;
        margin-top: 1.5rem;
    
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
    }

}