.wrapper {
    display: grid;
    grid-template-columns: auto 20rem;
    column-gap: 2rem;
    margin-bottom: 1rem;
    row-gap: 0.5rem;

    min-width: 952px;
}

.text {
    width: 100%;
    max-width: 600px;
    margin: 0;
    margin-top: 0.2rem;

    font-family: Segoe UI;

    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

.mark {
    background-color: rgb(250, 250, 137);
    color: var(--text);
    transition: 0.2s, smooth;
}

.markFocused {
    background-color: rgb(250, 197, 137);
    color: var(--text);
    transition: 0.2s, smooth;
}


.sideWrapper {
    position: relative;
}

.commentListWrapper {
    position: absolute;
}

.commentList {
    position: relative;
}

.comment {
    position: absolute;

    width: 22rem;
    min-height: 50px;

    cursor: default;
    box-shadow: none;
    background-color: white;

    border: 1px solid var(--border);
    border-radius: 0 15px 15px 15px;
    overflow: hidden;

    transition: 0.2s, smooth;
}

.commentFocused {
    position: absolute;

    width: 22rem;
    min-height: 50px;

    cursor: default;
    box-shadow: 0 1px 10px 2px #00000015;
    background-color: white;

    border: 1px solid var(--primary);
    border-radius: 0 15px 15px 15px;
    overflow: hidden;

    transition: 0.2s, smooth;
}

.commentButtonRow{
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
}

.commentContent {
    padding: 0.25rem 0.5rem;
    border-bottom: 1px solid var(--border);
}

.commentButtonAdd{
    padding: 0.5rem 1.2rem 0.5rem 0.8rem;
    transition: 0.2s, smooth;

    color: rgb(0, 146, 61);
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    
    border-right: 1px solid var(--border);
}

.commentButtonIgnore{
    padding: 0.5rem 1.2rem 0.5rem 0.8rem;
    transition: 0.2s, smooth;

    color: rgb(82, 82, 82);
    font-size: 14px;

    text-align: center;
}

.commentButtonAdd:hover, .commentButtonIgnore:hover {
    background-color: var(--background-hover);
}

.commentButtonAdd:active, .commentButtonIgnore:active {
    background-color: rgb(223, 223, 223);
}

.commentAddedText{
    padding: 0.3rem 1.2rem 0.3rem 0.8rem;

    color: rgb(0, 146, 61);
    font-weight: 600;
    font-size: 14px;
    text-align: center;

    width: fit-content;
    margin: auto;
}

.checkmark{
    height: 0.75rem;
    width: auto;
}


/* .comment {
    position: absolute;

    padding: 0.5rem 1rem;
    border: 1px solid var(--border);
    border-radius: 0 15px 15px 15px;
    margin-bottom: 0.5rem;
    transition: 0.2s, smooth;
    cursor: default;
    box-shadow: none;

    min-height: 50px;
} */

/* .commentFocused {
    position: absolute;

    padding: 0.5rem 1rem;
    border: 1px solid var(--primary);
    border-radius: 0 15px 15px 15px;
    margin-bottom: 0.5rem;
    transition: 0.2s, smooth;
    cursor: default;
    box-shadow: 0 1px 10px 2px #00000015;
} */

.commentSourceWrapper {
    margin-top: 1rem;
    width: 100%;
}

.commentLink {
    font-size: 14px;
    color: rgb(12, 0, 177);
    text-decoration: none;

    display: block; /* or inline-block */
    width: 100%; /* or any fixed width or max-width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.commentLink:hover {
    text-decoration: underline;
}


.wrapperGeneralFeedback{
    margin-bottom: 1rem;

    width: 100%;
    max-width: 600px;
}

.generalFeedback{
    padding: 1rem;
    border: 1px solid var(--border);
    border-radius: 0.5rem;

    margin-bottom: 1rem;
}