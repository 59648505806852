.wrapper{
    max-height: 36rem;
    margin-top: 0.5rem;
    
    background-color: white;
}

.chatWrapper {
    margin-top: 0.25rem;
    max-height: 30rem;
    overflow-y: auto;
}

.sentMessage {
    border-radius: 5px 5px 5px 0px;

    border: 1px solid grey;
    padding: 0.5rem;

    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.75rem;

    transition: 0.25s, smooth;
}

.responseMessage {
    border-radius: 5px 5px 0px 5px;
    background-color: rgb(243, 243, 243);

    padding: 0.75rem;
    margin-left: 1rem;
    margin-bottom: 0.5rem;

    transition: 0.25s, smooth;
}

.responseMessageText {
    max-width: 100%;
    white-space: pre-wrap;

    font-size: 14px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    margin: 0;
}

.chatLink{
    cursor: pointer;
    text-decoration: underline;
    color: blue;
}

.chatInput {
    display: grid;
    grid-template-columns: auto 2rem;
    align-items: center;

    padding: 0rem 0.5rem;

    border: 1px solid grey;
    border-radius: 10px;

    margin-top: 0.25rem;
}

.sendIcon{
    height: 1.2rem;
    width: auto;
}

.exampleCard{
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid var(--border);
    padding: 0.5rem;

    transition: 0.2s, smooth;

    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 14px;
}

.exampleCard:hover{
    background-color: var(--background-hover);
}


.plusIcon{
    display: none;

    padding: 0;
    margin: 0;
    margin-right: 0.5rem;
    font-size: 14px;
}

.exampleCard:hover > .plusIcon{
    display: block;
}