.wrapper {
    position: relative;
    display: grid;
    grid-template-columns: auto 20rem;
    column-gap: 2rem;
    margin-bottom: 1rem;
    row-gap: 0.5rem;
    overflow: visible;
}

.text {
    width: 100%;
    max-width: 600px;
    margin: 0;
    margin-top: 0.2rem;

    font-family: Segoe UI;

    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

.legend {
    position: sticky;
    top: 120px; /* Keep this value based on your needs */
    align-self: flex-start; /* Important for grid layouts */
    max-height: calc(100vh - 200px); /* Optional: ensures it doesn't overflow viewport */
    overflow-y: auto; /* Optional: allows legend to scroll if it gets too tall */
    overflow-x: visible;
}

.markLogos{
    background-color: rgb(199, 236, 255);
}

.markEthos{
    background-color: rgb(220, 199, 255);
}

.markPathos{
    background-color: rgb(255, 226, 199);
}

.markKairos{
    background-color: rgb(199, 201, 255);
}

.markTopos{
    background-color: rgb(199, 255, 221);
}

.markMythos{
    background-color: rgb(255, 199, 255);
}

.markTelos{
    background-color: rgb(255, 199, 199);
}


.icon{
    height: 0.75rem;
    width: auto;
}