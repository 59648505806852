.wrapper{
    width: fit-content;
    height: 2.5rem;
    padding: 0.5rem 0rem;

    display: flex;
    -webkit-display: flex;
    align-items: center;
}

.name{
    display: block;
    font-family: "acumin-pro", sans-serif;
    font-size: 16px;
    font-weight: var(--weight-heavy);
    height: fit-content;
    text-decoration: none;
    color: var(--text);
    border-bottom: 1px solid var(--background);
    transition: 0.2s, smooth;
}

.selectedMobile{
    border-bottom: 0px solid var(--text);
}

.selected{
    border-bottom: 1px solid var(--text);
}

.icon{
    display: none;
    height: 24px;
    width: auto;
}

.hide{
    display: block;
}

@media screen and (max-width: 800px) {
    .wrapper{
        height: 3.5rem;
        padding: 0.5rem 0.7rem;
    }

    .selectedMobile{
        border-bottom: 3px solid var(--text);
    }

    .name{
        display: none;
    }

    .icon {
        display: block;
    }
}

@media screen and (max-width: 400px) {
    .hide{
        display: none;
    }
}