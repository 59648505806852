.wrapper {
    border-radius: 0.2rem;
    padding: 0.6rem 0rem ;
}

.wrapperHuman {
    min-width: 7.8rem;
}

.wrapperAI {
    min-width: 7.8rem;
}

.colorHuman {
    background: linear-gradient(93.08deg, #38ef7d 0%, #14b3a5 100%);
    padding: 0.6rem 0.5rem ;
    text-align: center;
}

.colorProbablyHuman{
    background: linear-gradient(93.08deg, #FDC830 0%, #f39a35 100%);
    padding: 0.6rem 0.5rem ;
    text-align: center;
}

.colorAI {
    background: linear-gradient(93.08deg, #ED213A 0%, #ca3928 100%);
    padding: 0.6rem 0.5rem ;
    text-align: center;
}

.text {
    margin: auto;
    opacity: 1;
    font-size: 14px;
    font-weight: 600;

}

.comment{
    height: 100%;
    
    padding: 0.5rem 1rem;
    /* border: 1px solid var(--border);
    border-radius: 0 15px 15px 15px; */
    margin-bottom: 0.5rem;
    transition: 0.2s, smooth;
    cursor: default;
}

.borderRed{
    border-left: 5px solid var(--red);
}

.borderGreen{
    border-left: 5px solid var(--green);
}

.borderYellow{
    border-left: 5px solid var(--yellow);
}