.wrapper {
    width: 100%;
    height: 3.5rem;
    position: fixed;
    z-index: 100;
    background-color: var(--background);

    border-bottom: 1px solid var(--border);

    overflow: hidden;
}

.contentArea {
    width: fit-content;
    min-width: 800px;
    max-width: var(--max-view-width);

    margin: auto;
    height: 100%;

    display: grid;
    grid-template-columns: auto auto;
}

.leftWrapper {
    width: fit-content;
    height: 3.5rem;

    display: grid;
    grid-template-columns: auto auto auto auto auto;
    column-gap: 1.5rem;
    align-items: center;
}

.rightWrapper {
    width: fit-content;
    margin-left: auto;

    display: flex;
    -webkit-display: flex;
    column-gap: 1.5rem;
    align-items: center;
}

.rightWrapper>* {
    margin-top: 0rem;
}

.hideOnTabletOrSmaller {
    display: block;
}

@media screen and (max-width: 800px) {
    .contentArea {
        width: 90vw;
        min-width: 300px;
    }

    .leftWrapper {
        column-gap: 0.5rem;
    }

    .hideOnTabletOrSmaller {
        display: none;
    }
}