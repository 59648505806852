.internalWrapper {
    width: 100%;
    /* max-width: 1000px; */
}

.infoGroup {
    display: none;
    grid-template-columns: auto auto;
    margin-bottom: 0.5rem;
}

.tabFill {
    display: none;
    border-bottom: 2px solid var(--border);
    width: 100%;
    position: relative;

    height: 2.5rem;
    margin-top: 1rem;
}

.tabFillFlex {
    position: absolute;
    bottom: -2px;

}

.tab {
    font-size: 16px;
    border-bottom: 2px solid var(--border);

    padding: 0.2rem 1rem;
    cursor: pointer;
    transition: 0.3s, smooth;

}

.tabClicked {
    border-bottom: 2px solid var(--primary);
}


.card {
    border-radius: 10px;

    background-color: var(--background);
    box-shadow: 0 1px 10px 2px #00000015;

    overflow: hidden;
}


.sidebarWrapper {
    display: block;
}

.backButtonWrapper{
    display: none;
}

.scrollWrapper {
    padding-top: 3.5rem;
    height: calc(100vh);
    width: calc(100vw - 350px);

    overflow-y: auto;
    overflow-x: hidden;

    padding-bottom: 8rem;

}

.mainWrapper {
    padding-left: 2rem;
    padding-right: 1rem;
    height: calc(100vh);
    width: calc(100vw - 700px);
    margin: none;
}

.mainContent {
    padding-top: 1rem;
    width: fit-content;

    margin: auto;

    padding-bottom: 8rem;
}

.exportButtonWrapper {
    display: block;
    width: 100%;
    margin-left: 87%;
}

.link {
    font-weight: 500;
    color: rgb(0, 0, 177);
    text-decoration: underline;
    margin-left: 2rem;
    font-size: 14px;
    text-align: right;
}

.text {
    width: 100%;
    max-width: 600px;
    margin: 0;
    margin-top: 0.2rem;

    font-family: Segoe UI;

    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

.mark {
    background-color: rgb(253, 218, 218);
    color: var(--text);
}

.date {
    margin-top: 1.5rem;
    font-size: 15px;
    color: var(--text-off);
    margin-left: auto;
}

.paragraphWrapper {
    display: grid;
    grid-template-columns: auto 20rem;
    column-gap: 2rem;
    margin-bottom: 1rem;
    row-gap: 0.5rem;
}

.paragraphWrapperAI {
    display: grid;
    grid-template-columns: auto 20rem;
    column-gap: 2rem;
    margin-bottom: 1rem;
    row-gap: 0.5rem;

    position: relative;
}

.resultBox {
    display: block;
    /* padding: 0.2rem 0; */
    margin-left: auto;
    user-select: none;

    background-color: var(--background);
    position: absolute;
    width: 20rem;
    top: -1px;
    right: 0;

    height: 100%;
}

.resultBoxMobile {
    display: none;
    margin: auto;
    user-select: none;
    margin-top: 1rem;
}

.resultPercentage {
    display: block;
}

.paragraphFactCheckWrapper {
    display: grid;
    grid-template-columns: auto 20rem;
    column-gap: 2rem;
    margin-bottom: 1rem;
    row-gap: 0.5rem;
}

@media screen and (max-width: 1684px) {
    .mainWrapper {
        width: calc(100vw - 350px);
    }

    .infoGroup{
        display: none;
    }

    .mainContent {  
        padding-top: 1rem;
    }
}

@media screen and (max-width: 1334px) {
    .mainWrapper {
        width: 952px;
        height: 100%;

        margin: auto;
    }

    .mainContent {
        padding-top: 2rem;
    }

    .sidebarWrapper {
        display: none;
    }

    .infoGroup{
        display: grid;
    }

    .backButtonWrapper{
        display: block;
    }

    .scrollWrapper {
        height: 100vh;
        width: 100vw;

        overflow-y: auto;
    }

    .tabFill {
        display: block;
    }
}

@media screen and (max-width: 984px) {
    .mainWrapper {
        width: calc(100vw - 2rem);
        max-width: 952px;
    }
}

@media screen and (max-width: 800px) {

    .mainWrapper {
        padding-left: 0rem;
        padding-right: 0rem;
    }

    .link {
        text-align: center;
        margin-left: 0;
    }

    .text {
        width: 90vw;
    }

    .infoGroup {
        display: block;
    }

    .date {
        margin-top: 0rem;
        padding-bottom: 1rem;
    }

    .paragraphWrapper {
        grid-template-columns: auto;
        row-gap: 0rem;
        column-gap: 0rem;
        border-top: none;
        margin-bottom: 1rem;
    }

    .paragraphWrapperAI {
        grid-template-columns: auto;
        row-gap: 0rem;
        column-gap: 0rem;
        border-top: none;
        margin-bottom: 1rem;
    }

    .resultBox {
        display: none;
    }

    .resultBoxMobile {
        display: block;
    }
}

@media screen and (max-width: 500px) {

    .exportButtonWrapper {
        display: none;
    }

    .tabFill {
        max-width: 90vw;
    }
}