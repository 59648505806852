.wrapper {
    width: 100%;
}

.text {
    width: 100%;
    max-width: 600px;
    margin: 0;
    margin-top: 0.2rem;

    font-family: Segoe UI;

    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

.textarea {
    border-radius: 0.2rem;
    border: 1px solid var(--border);
    height: auto;
    max-height: 25rem;
    min-height: 10rem;
    outline: 0px none transparent;

    width: 100%;
    max-width: var(--max-view-width);
    resize: none;
    overflow-y: scroll;

    font-family: 'Segoe UI', system-ui, sans-serif;
    font-size: 16px;
    padding: 0.7rem;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    transition: 0.2s, smooth;
}

.textarea:focus {
    border: 1px solid var(--primary);

}


.actionGroup {
    display: grid;
    grid-template-columns: 8rem 13.5rem auto;
    column-gap: 0.5rem;
}


/* Edit button*/
.editIconButton {
    width: 2rem;
    height: 2rem;

    outline: none;
    border: none;

    background-color: var(--background);
    border-radius: 50%;

    cursor: pointer;
    text-align: center;
    vertical-align: middle;

    transition: 0.1s, smooth;
}

.editIconButton:hover,
.addIconButton:focus {
    background-color: #e6e6e6;
}

.editIcon {
    height: 16px;
    width: 16px;
    margin: auto;
}