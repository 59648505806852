.wrapper{
    width: 90%;
    max-width: 310px;
    min-width: 250px;

    margin: auto;

    padding-top: 5rem;
}

.img{
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.topGrid{
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    text-align: left;
}


.linkItemWrapper{
    margin-top: 1rem;
}

.linkItem{
    font-family: "acumin-pro", sans-serif;
    padding: 0.8rem;
    padding-top: 0.6rem;
    border-bottom: 1px solid var(--border );
    margin: none !important;
    text-align: left;
}

.linkItem:hover{
    background-color: var(--background-hover);
}