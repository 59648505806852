.modalWrapper{

    width: 100vw;
    height: 100vh;
    position: fixed;
    
    top: 0;
    left: 0;
    background-color: #000000BB;

    z-index: 2000;
}

.modalBox{
    background-color: var(--background);
    width: fit-content;
    height: fit-content;
    overflow-y: scroll;

    max-width: 90vw;
    max-height: 70vh;
    min-width: 20rem;

    padding: 2rem;

    z-index: 2001;

    margin: auto;
    margin-top: 20vh;
}

@media screen and (max-width: 800px) {
    .modalBox{
        background-color: var(--background);
        padding: 2rem 1rem;
        margin-top: 15vh;
        max-height: 80vh;
    }  
}
