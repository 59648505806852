.input {
    border-radius: 0.2rem;
    border: 1px solid var(--border);
    outline: none;

    width: calc(100% - 2rem - 2px);
    max-width: 30rem;
    resize: none;
    overflow-y: scroll;

    font-family: 'Poppins' serif;
    font-size: 16px;
    padding: 0.9rem 1rem;
}

.input:focus {
    border: 1px solid var(--text);

}

.actionGroup {
    display: grid;
    grid-template-columns: 12rem auto auto;
    column-gap: 0.5rem;
    margin-top: 0.5rem;
}

.topButtons {
    display: grid;
    grid-template-columns: 20rem auto;
    column-gap: 2rem;
    row-gap: 1rem;
}

.topButtons>label {
    margin-bottom: 0.3rem;
}

.fileListLabels {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 2rem;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid var(--border);
}

.fileListLabelsRight {
    margin-left: auto;
    margin-bottom: 0.4rem;
    font-size: 14px;
    font-weight: 500;
}

.fileReselector {
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: fit-content;
    width: fit-content;
    cursor: pointer;

    background-color: var(--background);
}

.fileReselector:hover {
    background-color: var(--background-hover);
}

.fileList {
    display: grid;
    grid-template-columns: auto;
    row-gap: 0.5rem;
    min-height: 16rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid var(--border);
}

.fileItem {
    padding: 1rem;
    border-radius: 5px;
    background-color: #f5f5f5;
    height: fit-content;

    display: grid;
    grid-template-columns: auto auto;
}

.fileSelectorWrapper {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;

    height: fit-content;
    width: 100%;

    border-radius: 5px;
    background-color: var(--background-hover);
    transition: 0.3s, smooth;
}

.fileSelector {
    padding: 1rem;
    height: fit-content;
    width: calc(100% - 2rem);
    text-align: center;
    margin: auto;
    cursor: pointer;
    font-weight: 500;
    
    display: block;
}

.fileSelectorWrapper:hover {
    background-color: var(--border);
}

.icon {
    width: 1.5rem;
    height: auto;
}

.fileItemRight {
    margin-left: auto;
    font-size: 14px;

    display: grid;
    grid-template-columns: auto 3.5rem;
    column-gap: 2rem;
}

.topBarRight{
    margin-left: auto;
    font-size: 14px;
}


/*Folder selector*/

.folderSelectionActionWrapper {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-items: center;
    column-gap: 1rem;
    row-gap: 1rem;
}

.listHeader {
    width: fit-content;
    min-width: 50vw;
    display: grid;
    grid-template-columns: auto 7rem;

    padding: 0 0.4rem;

    border-bottom: 1px solid var(--border);
    margin-bottom: 0.5rem;

}

.listHeader * p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.listHeaderButton {
    background-color: var(--background);
    outline: none;
    border: none;

    cursor: pointer;
    text-align: center;

    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px 5px 0 0;

    transition: 0.2s, smooth;
}

.listHeaderButtonMarked {
    background-color: var(--primary);
}

.listHeaderButtonMarked:hover {
    background-color: var(--primary-hover) !important;
}

.listHeaderButtonMarked>p {
    color: var(--text-light);
}

.listHeaderButton:hover {
    background-color: var(--background-hover);
}

.listHeaderIconButton {
    background-color: var(--background);
    outline: none;
    border: none;

    cursor: pointer;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;

    height: 2rem;
    width: 2rem;
}

.listHeaderIconButton:hover {
    background-color: var(--background-hover);
}

.listHeaderIconButtonDisabled {
    background-color: var(--background);
    outline: none;
    border: none;
    color: var(--border);

    cursor: default;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;

    height: 2rem;
    width: 2rem;
}

.listHeaderIconButtonDisabled>img {
    opacity: 0.4;
}

.arrowIcon {
    margin-top: 5px;
    height: auto;
    width: 20px;
}

@media screen and (max-width: 800px) {
    .topButtons {
        grid-template-columns: auto;
    }

    /* Modal */
    .folderSelectionActionWrapper {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }

}