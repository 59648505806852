.listItem {
    padding: 0 0.5rem;
    border-radius: 0.2rem;
    border-bottom: 1px solid var(--background-hover);
    align-items: center;

    display: grid;
    grid-template-columns: auto 2rem;
    column-gap: 1rem;

    transition: 0.2s, smooth;
    cursor: pointer;
}

.listItem:hover {
    background-color: var(--background-hover);
}

.listItemSelected {
    padding: 0 0.5rem;
    border-radius: 0.2rem;
    border-bottom: 1px solid var(--background-hover);
    align-items: center;

    display: grid;
    grid-template-columns: auto 2rem;
    column-gap: 1rem;
    background-color: var(--background-hover);
}

.listItemClickableContent {
    padding: 0.5rem 0;

    display: grid;
    grid-template-columns: 2rem auto auto;
    column-gap: 0.6rem;

    align-items: center;

}

.iconWrapper {
    width: 24px;
    height: auto;
    text-align: center;
}

.icon {
    height: 24px;
    width: auto;
}

.defaultTextLength {
    display: block;
    font-size: 15px;
}

.mobileTextLength {
    display: none;
    font-size: 15px;
}

.right {
    margin-left: auto;
    font-size: 14px;
    color: var(--text-off);
}


.optionWrapper {
    display: block;

    position: relative;
    width: fit-content;
    height: fit-content;
}

.optionIconButton {
    width: 2rem;
    height: 2rem;

    outline: none;
    border: none;

    background-color: #00000000;
    border-radius: 50%;

    cursor: pointer;
    text-align: center;
    vertical-align: middle;

    transition: 0.2s, smooth;
}

.optionIconButton:hover,
.optionIconButton:focus {
    background-color: rgb(209, 209, 209);
}

.optionIcon {
    width: auto;
    height: 0.8rem;
}

.optionMenu {
    position: absolute;
    top: 0.5rem;
    right: 0;

    width: fit-content;
    min-width: 8rem;

    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: 5px;

    display: grid;
    grid-template-columns: auto;

    z-index: 100;

    overflow: hidden;
}

.optionMenuItem {
    padding: 0.5rem 1rem;
    border: none;
    border-bottom: 1px solid var(--border);

    text-align: left;
    background-color: var(--background);

    outline: none;
    transition: 0.2s, smooth;
    cursor: pointer;

    font-size: 14px
}

.optionMenuItem:hover,
.optionMenuItem:focus {
    background-color: var(--background-hover);
}

.inputInline {
    display: grid;
    grid-template-columns: 25rem 9rem;
    column-gap: 1rem;
    row-gap: 1rem;
}

.input {
    border-radius: 0.2rem;
    border: 1px solid var(--border);
    outline: none;

    width: calc(100% - 2rem - 2px);
    max-width: 30rem;
    resize: none;
    overflow-y: scroll;

    font-family: 'Poppins' serif;
    font-size: 16px;
    padding: 0.9rem 1rem;
}

.input:focus {
    border: 1px solid var(--text);
}

@media screen and (max-width: 800px) {
    .listItem {
        padding: 0.5rem 0;
        border-radius: 0;
        align-items: center;

        display: grid;
        grid-template-columns: auto 2rem;
        column-gap: 1rem;
    }

    .listItem:hover {
        background-color: var(--background);
    }

    .listItemSelected {
        padding: 0.5rem 0;
        border-radius: 0;
        align-items: center;

        display: grid;
        grid-template-columns: auto 0rem;
        column-gap: 1rem;
        background-color: var(--background-hover);
    }

    .listItemClickableContent {
        padding: 0;
        grid-template-columns: 24px auto;
        align-items: center;
    }

    .defaultTextLength {
        display: none;
    }

    .mobileTextLength {
        display: block;
    }

    .inputInline {
        display: grid;
        grid-template-columns: auto;
        column-gap: 1rem;
        row-gap: 1rem;
    }

    .optionWrapper {
        display: none;
    }

}