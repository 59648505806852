.loader {
    border: 8px solid var(--border);
    border-top: 8px solid var(--text);
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}