.wrapper{
    position: relative;
    width: 100%;
    height: 340px;
}

.content{
    padding: 1rem;
    position: absolute;
    z-index: 12;
    bottom: 32px;
    left: 0;
    width: 300px;
}