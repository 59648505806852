

.infoBox{
    border-radius: 1rem;
    border: 1px solid var(--primary);
    background-color: var(--primary-background);

    padding: 0 2rem 2rem 2rem;

    margin-bottom: 2rem;
}