.input {
    border-radius: 0.2rem;
    border: 1px solid var(--border);
    outline: none;

    width: calc(100% - 2rem - 2px);
    max-width: 30rem;
    resize: none;
    overflow-y: scroll;

    font-family: 'Poppins' serif;
    font-size: 16px;
    padding: 0.9rem 1rem;
}

.input:focus {
    border: 1px solid var(--text);

}

.actionGroup {
    display: grid;
    grid-template-columns: 12rem auto auto;
    column-gap: 0.5rem;
}

.topButtons {
    display: grid;
    grid-template-columns: auto 20rem;
    column-gap: 2rem;
    row-gap: 1rem;
}

.topButtons>label {
    margin-bottom: 0.3rem;
}

.textarea {
    border-radius: 0.2rem;
    border-color: var(--border);
    height: auto;
    max-height: 55vh;
    min-height: 45vh;
    outline: none;

    width: 100%;
    max-width: var(--max-view-width);
    resize: none;
    overflow-y: scroll;

    font-family: 'Poppins', serif;
    font-size: 16px;
    padding: 1rem;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.textarea:focus {
    border-color: var(--text);
}

.topBar {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 2rem;
    width: fit-content;
    margin-top: 2rem;
    margin-bottom: 0.3rem;
}

.topBarRight {
    margin-left: auto;
    font-size: 14px;
}

/*Folder selector*/

.folderSelectionActionWrapper {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-items: center;
    column-gap: 1rem;
    row-gap: 1rem;
}

.listHeader {
    width: fit-content;
    min-width: 50vw;
    display: grid;
    grid-template-columns: auto 7rem;

    padding: 0 0.4rem;

    border-bottom: 1px solid var(--border);
    margin-bottom: 0.5rem;

}

.listHeader * p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.listHeaderButton {
    background-color: var(--background);
    outline: none;
    border: none;

    cursor: pointer;
    text-align: center;

    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px 5px 0 0;

    transition: 0.2s, smooth;
}

.listHeaderButtonMarked {
    background-color: var(--primary);
}

.listHeaderButtonMarked:hover {
    background-color: var(--primary-hover) !important;
}

.listHeaderButtonMarked>p {
    color: var(--text-light);
}

.listHeaderButton:hover {
    background-color: var(--background-hover);
}

.listHeaderIconButton {
    background-color: var(--background);
    outline: none;
    border: none;

    cursor: pointer;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;

    height: 2rem;
    width: 2rem;
}

.listHeaderIconButton:hover {
    background-color: var(--background-hover);
}

.listHeaderIconButtonDisabled {
    background-color: var(--background);
    outline: none;
    border: none;
    color: var(--border);

    cursor: default;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;

    height: 2rem;
    width: 2rem;
}

.listHeaderIconButtonDisabled>img {
    opacity: 0.4;
}

.arrowIcon {
    margin-top: 5px;
    height: auto;
    width: 20px;
}

@media screen and (max-width: 800px) {
    .textarea {
        max-height: 55vh;
    }

    .topButtons {
        grid-template-columns: auto;
    }

    /* Modal */
    .folderSelectionActionWrapper {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }

}