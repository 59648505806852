.wrapper {
    display: grid;
    grid-template-columns: auto 20rem;
    column-gap: 2rem;
    margin-bottom: 1rem;
    row-gap: 0.5rem;
}

.text {
    width: 100%;
    max-width: 600px;
    margin: 0;
    margin-top: 0.2rem;

    font-family: Segoe UI;

    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

.markGreen{
    background-color: rgb(199, 255, 199);
}

.markYellow{
    background-color: rgb(255, 250, 182);
}

.markRed{
    background-color: rgb(253, 218, 218);
}

.icon{
    height: 0.75rem;
    width: auto;
}