body {
  margin: 0;
  /*background: linear-gradient(13deg, rgba(240,240,255,1) 0%, rgba(255,255,255,1) 100%);*/
  background-color: var(--background);
  color: var(--text);
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  font-family: 'Poppins', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scrollbar-gutter: stable;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

a {
  text-decoration: none;
}

input[type=text], input[type=password] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 12px;
}


/* Track */
::-webkit-scrollbar-track {
  background: #00000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--grey);
  border-radius: 5px;
  border: 2px solid var(--background);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--greyer);
}