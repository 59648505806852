.wrapper{
    width: 90%;
    max-width: 300px;
    min-width: 250px;

    margin: auto;

    padding-top: 5rem;
}

.boldText{
    font-weight: 500;
}

.topGrid{
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    border-bottom: 1px solid var(--border);
}

.deleteWrapper{
    margin-top: 6rem;
}

.linkItemWrapper{
    margin-top: 1rem;
}

.linkItem{
    font-family: "acumin-pro", sans-serif;
    padding: 0.8rem;
    padding-top: 0.6rem;
    border-bottom: 1px solid var(--border );
    margin: none !important;
    text-align: center;
    transition: 0.2s, smooth;
}

.linkItem:hover{
    background-color: var(--background-hover);
}