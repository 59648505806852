.listHeader {
    width: 952px;

    display: grid;
    grid-template-columns: auto 9rem 7rem;

    padding: 0 0.4rem;

    border-bottom: 1px solid var(--border);
    margin-bottom: 0rem;
}

.folderP {
    display: none;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.folderPInHeader {
    display: block;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.listHeaderButton {
    display: block;
    background-color: var(--background);
    outline: none;
    border: none;
    border-radius: 5px 5px 0 0;
    padding-top: 0.4rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.4rem;

    cursor: pointer;
    text-align: center;

    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;

    transition: 0.2s, smooth;
}

.listHeaderButtonMarked {
    background-color: var(--primary);
}

.listHeaderButtonMarked:hover {
    background-color: var(--primary-hover) !important;
}

.listHeaderButtonMarked>p {
    color: var(--text-light);
}

.listHeaderButton:hover {
    background-color: var(--background-hover);
}

.listHeaderButtonPC {
    display: block;
    background-color: var(--background);
    outline: none;
    border: none;
    border-radius: 5px 5px 0 0;
    padding-top: 0.3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.2rem;

    cursor: pointer;
    text-align: center;

    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: var(--text);

    transition: 0.2s, smooth;
}

.listHeaderButtonPC:hover,
.listHeaderButtonPC:focus {
    background-color: var(--background-hover);
}

.listHeaderIconButton {
    background-color: var(--background);
    outline: none;
    border: none;

    cursor: pointer;
    border-radius: 50%;

    height: 2rem;
    width: 2rem;
}

.listHeaderIconButton:hover {
    background-color: var(--background-hover);
}

.listHeaderIconButtonDisabled {
    background-color: var(--background);
    outline: none;
    border: none;
    color: var(--border);

    cursor: default;
    border-radius: 50%;

    height: 2rem;
    width: 2rem;
}

.listHeaderIconButtonDisabled>img {
    opacity: 0.4;
}

.arrowIcon {
    margin-top: -3px;
    width: 16px;
    height: auto;
}

.listItem {
    padding: 0.5rem;
    margin-bottom: 0.2rem;
    border-radius: 0.2rem;
    align-items: center;

    display: grid;
    grid-template-columns: 2.5rem auto auto 2rem;
    column-gap: 1rem;
}

.listItem:hover {
    background-color: var(--background-hover);
}

.defaultTextLength {
    width: auto;
    max-width: 75vw;
    display: block;
    overflow-wrap: break-word;
}

.mobileTextLength {
    width: auto;
    max-width: 75vw;
    display: none;
    overflow-wrap: break-word;
}

.right {
    margin-left: auto;
    font-size: 14px;
    color: var(--text-off);
}

.showMore {
    padding-top: 1rem;
    width: fit-content;
    margin: auto;
}

@media screen and (max-width: 952px) {
    .listHeader {
        width: 100%;

    }
}

@media screen and (max-width: 800px) {
    .listItem {
        padding: 0.5rem 0;
        border-radius: 0;
        align-items: center;

        display: grid;
        grid-template-columns: 3rem auto;
        column-gap: 1rem;
    }

    .listItem:hover {
        background-color: var(--background);
    }

    .defaultTextLength {
        display: none;
    }

    .mobileTextLength {
        display: block;
    }

    .listHeaderButtonPC {
        display: none;
    }


    .listHeader {
        grid-template-columns: auto 7rem;
    }

    .folderP {
        display: block;
    }
    
    .folderPInHeader {
        display: none;
    }
}