.textarea{
    border-radius: 0.2rem;
    border-color: var(--border);
    height: 7rem;
    outline: none;

    width: 100%;
    max-width: var(--max-view-width);
    resize: none;
    overflow-y: scroll;

    font-family: 'Segoe UI', system-ui, sans-serif;
    font-size: 16px;
    padding: 0.7rem;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    transition: 0.2s, smooth;
}

.textarea:focus {
    border-color: var(--primary);
}