/* Wrapper */
.wrapper{
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 0rem;
    max-width: auto;
    width: 100%;
    margin: auto;
    cursor: pointer;
    height: fit-content;
    align-items: center;
    background-color: var(--background);

    border: none;
    outline: none;
}

.wrapperRounded{
    background-color: var(--light-grey);
    border-radius: 2rem;
    transition: smooth, 0.3s;
    padding: 0.6rem;
    place-content: center;
}

.wrapperDisabledRounded{
    border: 1px solid var(--greyer);
    background-color: var(--lighter-grey);
    border-radius: 2rem;
    padding: 0.6rem;
    transition: smooth, 0.2s;
    cursor: default;
    place-content: center;
}

.wrapperRounded:hover, .wrapperRounded:focus{
    background-color: var(--grey);
}

.wrapperPrimary{
    border: 1px solid var(--text);
    background-color: var(--text);
    border-radius: 0.2rem;
    transition: smooth, 0.2s;
    padding: 0.2rem 1.6rem;
    align-items: center;
    color: var(--text-light); 
}

.wrapperPrimary:hover, .wrapperPrimary:focus{
    background-color: #333333;
    color: var(--text-light); 
}

.wrapperOutlined{
    border: 1px solid var(--text);
    border-radius: 0.2rem;
    transition: smooth, 0.2s;
    color: var(--text);
    padding: 0.2rem 1.6rem;
    background-color: var(--background);
}

.wrapperOutlined:hover, .wrapperOutlined:focus{
    border: 1px solid var(--text);
    background-color: var(--background-hover);
}

.wrapperOutlinedPrimary{
    border: 1px solid var(--border);
    border-radius: 0.2rem;
    transition: smooth, 0.2s;
    color: var(--text);
    padding: 0.2rem 1.6rem;
    background-color: var(--background);
}

.wrapperOutlinedPrimary:hover, .wrapperOutlinedPrimary:focus{
    border: 1px solid var(--primary);
}

.wrapperPrimaryBlue{
    border: 1px solid var(--primary);
    background-color: var(--primary);
    border-radius: 0.2rem;
    transition: smooth, 0.2s;
    padding: 0.2rem 1.6rem;
    align-items: center;
    color: var(--text-light);
}

.wrapperPrimaryBlue:hover, .wrapperPrimaryBlue:focus{
    border: 1px solid var(--primary-hover);
    background-color: var(--primary-hover);
}

.wrapperNegative{
    border: 1px solid var(--red);
    border-radius: 0.2rem;
    transition: smooth, 0.2s;
    color: var(--red);
    padding: 0.2rem 1.6rem;
    background-color: var(--background);
}

.wrapperNegative:hover, .wrapperNegative:focus{
    border: 1px solid var(--red-hover);
    background-color: var(--background-hover);
    color: var(--red-hover);
}

.wrapperDisabled{
    border: 1px solid var(--border);
    border-radius: 0.2rem;
    padding: 0.2rem 1.6rem;
    background-color: var(--background-hover);
    cursor: default;
    color: var(--text); 
}


.center{
    place-content: center;
}

.left{
    place-content: left;
}


/* Icon */
.icon{
}

.iconRounded{
    height: 24px;
    width: auto;
}

.iconPrimary{
    height: 24px;
    width: auto;
    margin-right: 0.5rem;
}

.iconOutlined{
    height: 24px;
    width: auto;
    margin-right: 0.5rem;
}

/* Text */
.text{
    font-size: 1rem;
    font-weight: 500;
    padding: 0.4rem 0.4rem;    
}

.textRounded{
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.4rem 0.4rem;   
}

.textDisabledRounded{
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.4rem 0.4rem;   
    color: var(--border);
}

.textPrimary{
    font-size: 1rem;
    font-weight: var(--weight-normal);
    padding: 0.5rem 0.2rem;  
}

.textOutlined{
    font-size: 1rem;
    font-weight: var(--weight-normal);
    padding: 0.5rem 0.2rem;  
}

.textPrimaryBlue{
    font-size: 1rem;
    font-weight: var(--weight-normal);
    padding: 0.5rem 0.2rem; 
}

.textDisabled{
    font-size: 1rem;
    font-weight: var(--weight-normal);
    padding: 0.5rem 0.2rem;  
}
