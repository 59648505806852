.feedbackPreviewButton {
    display: block;
    position: fixed;
    right: 0;
    top: 7rem;

    padding: 1rem 2rem;
    background-color: var(--background);
    color: var(--text);
    border: 1px solid var(--text);
    border-radius: 5px;
    transition: 0.3s, smooth;

    font-size: 14px;

    z-index: 2;
    cursor: pointer;
}

.feedbackPreviewButton:hover {
    background-color: var(--background-hover);
}

.modalWrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;

    top: 0;
    left: 0;
    background-color: #000000BB;

    z-index: 1101;
}

.modalBox {
    background-color: var(--background);
    width: fit-content;
    height: fit-content;
    overflow-y: scroll;

    max-width: 90vw;
    max-height: 70vh;

    padding: 2rem 3rem;

    z-index: 1102;

    margin: auto;
    margin-top: 20vh;
}

.feedbackButtonGrid {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    column-gap: 0.5rem;

    padding: 0.5rem 0;
}

.feedbackButton {
    border: 5px solid var(--background);
    outline: none;
    background-color: var(--background);
    border-radius: 50%;

    transition: 0.3s, smooth;

    width: 74px;
    height: 74px;

    padding: 0 0;
    margin: 0 0;

    cursor: pointer;
}

.feedbackButton:hover {
    border: 5px solid var(--background-hover);
}

.feedbackButtonSelected {
    border: 5px solid var(--text);
    outline: none;
    background-color: var(--text);
    border-radius: 50%;

    transition: 0.3s, smooth;

    width: 74px;
    height: 74px;

    padding: 0 0;
    margin: 0 0;

    cursor: pointer;
}

.icon {
    width: 64px;
    height: 64px;
}

.textarea {
    border-radius: 0.2rem;
    border-color: var(--border);
    height: auto;
    max-height: 16rem;
    min-height: 6rem;
    outline: none;

    width: 100%;
    max-width: var(--max-view-width);
    resize: none;
    overflow-y: scroll;

    font-family: 'Poppins', serif;
    font-size: 16px;
    padding: 0.5rem;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.textarea:focus {
    border-color: var(--text);
}


@media screen and (max-width: 800px) {
    .feedbackPreviewButton {
        display: none;
    }


    .feedbackButtonGrid {
        column-gap: 0.2rem;
    }

    .feedbackButton {
        border: 2px solid var(--background);

        width: 44px;
        height: 44px;
    }

    .feedbackButton:hover {
        border: 2px solid var(--background-hover);
    }

    .feedbackButtonSelected {
        border: 2px solid var(--text);

        width: 44px;
        height: 44px;
    }

    .icon {
        width: 40px;
        height: 40px;
    }

}