.wrapper {
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    background-color: var(--background-hover);

    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
    row-gap: 0.5rem;

    width: 100%;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.row {
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: 2.5rem auto;
}

.icon {
    height: 32px;
    width: auto;
}

.text {
    font-weight: 500;
}

.link {
    font-weight: 500;
    color: rgb(0, 0, 177);
    text-decoration: underline;
    margin-left: 2rem;
    font-size: 14px;
    text-align: right;
}

@media screen and (max-width: 800px) {
    .wrapper {
        margin-bottom: 1rem;
        grid-template-columns: auto;
    }

    .link {
        text-align: center;
        margin-left: 0;
    }
}