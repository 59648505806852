.contentWrapper {
    padding-top: 3rem;
    padding-bottom: 5rem;

    width: 70rem;
}

.actionSection {
    margin-top: 3rem;

    display: grid;
    grid-template-columns: auto 390px;
    column-gap: 12rem;
}

.chevron {
    width: 1rem;
    height: auto;
}

.title {
    font-size: 40px;
    margin-bottom: 0.5rem;
    margin-top: 0;
    /* color: rgb(185, 111, 0); */
}

.p {
    width: 370px;
    font-size: 20px;
}




.actionButtonGrid {
    margin-top: 1rem;

    display: grid;
    grid-template-columns: auto;
    row-gap: 1rem;
}

.actionButton {
    width: 390px;
    height: fit-content;

    padding: 0.8rem 1rem;
    border-radius: 10px;

    background-color: var(--background);
    box-shadow: 0 1px 10px 2px #00000015;

    display: grid;
    grid-template-columns: 4.5rem auto 1rem;
    column-gap: 0.5rem;

    align-items: center;

    transition: 0.2s, smooth;

    backface-visibility: hidden;
    transform: translateZ(0);
}

.actionButtonBlack {
    background-color: var(--text);
    box-shadow: none;
}

.actionButton:hover {
    transform: scale(1.016);
    box-shadow: 0 1px 15px 2px #00000025;
}

.actionButtonBlack:hover {
    box-shadow: 0 1px 10px 2px #00000015;
}

.actionButton>.actionButtonDescription {
    color: var(--text-off);
}

.actionButtonBlack>div>.actionButtonTitle,
.actionButtonBlack>div>.actionButtonDescription {
    color: var(--text-light);
}

.actionButtonTitle {
    margin: 0;
    font-size: 18px;
}

.actionButtonDescription {
    font-size: 14px;
    margin: 0;
}

.actionButtonIcon {
    width: 4rem;
    height: 4rem;
    margin-right: 0.5rem;
}




.blogSection {
    margin-top: 10rem;
    /* width: calc( 70rem + ((100vw - 70rem) / 2)); */
    width: 100%;
    height: 38rem;

    position: relative;
}

.carouselWrapper {
    position: absolute;
    left: calc(((100vw - 70rem) / 2) * -1);
    top: 4rem;

    width: 100vw;
}

.blogCard {
    width: calc(100% - 1rem);
    height: calc(100% - 2rem);

    margin: 1rem 0.5rem;
    padding: 2rem;
    padding-bottom: 0;
    border-radius: 15px;

    background-color: var(--background);
    box-shadow: 0 1px 10px 2px #00000015;

    transition: 0.2s, smooth;

    backface-visibility: hidden;
    transform: translateZ(0);

    position: relative;
}

.blogCard:hover {
    transform: scale(1.016);
}

.blogCardBottomImageWrapper {
    position: absolute;
    bottom: 0;
    left: 3rem;
}

.blogCardPrecissionIllustrationBackground {
    background-image: url('../../assets/precissionIllustration.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.blogCardAgreementIllustrationBackground {
    background-image: url('../../assets/agreementIllustration.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.blogCardImage {
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-top: 4rem;
    color: #cfcfcf;
}

.link {
    text-decoration: underline;
}


.tutorialWrapper {
    width: 800px;
    max-width: 800px;
}

.centered {
    margin: auto;
}

@media screen and (max-width: 1200px) {
    .contentWrapper {
        width: 100%;
    }

    .tutorialWrapper {
        width: 100%;
    }

    .actionSection {
        column-gap: 6rem;
    }
}

@media screen and (max-width: 900px) {
    .contentWrapper {
        padding-top: 3rem;
        padding-bottom: 0rem;

        width: 100%;
    }

    .actionSection {
        margin-top: 3rem;
        width: fit-content;
        margin: auto;

        display: grid;
        grid-template-columns: auto;
        column-gap: 0rem;
        row-gap: 2rem;
    }

    .actionButton {
        width: 100%;
        max-width: 400px;
        height: fit-content;

        padding: 0.6rem 0.8rem;
        border-radius: 10px;

        display: grid;
        grid-template-columns: 3.5rem auto 1.5rem;
        column-gap: 0.5rem;
    }

    .actionButtonIcon {
        width: 3rem;
        height: 3rem;
        margin-right: 0rem;
    }
}

@media screen and (max-width: 800px) {
    .contentWrapper {
        padding-top: 3rem;
    }

    .p {
        width: auto;
        font-size: 16px;
    }
}

@media screen and (max-width: 380px) {
    .p {
        width: 95%;
        font-size: 16px;
    }
}