.wrapper {
    width: calc(600px + 22rem);
}

.titleWrapper {
    width: 100%;

    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
}

.titleWrapperLeft {
    margin-left: auto;
}

.cardList {
    display: grid;
    grid-template-columns: auto;
    row-gap: 0.3rem;
    padding-top: 0.3rem;
    margin-top: 1rem;
    transition: 0.3, smooth;
}

.card {
    padding: 1rem;
    border-radius: 5px;
    background-color: var(--primary-background);
    height: fit-content;

    display: grid;
    grid-template-columns: 2rem auto 11rem;
    column-gap: 0.5rem;

    transition: 0.3, smooth;
}

.cardItemRight {
    margin-left: auto;
    font-size: 14px;

    display: grid;
    grid-template-columns: 6rem 2rem 2rem;
    column-gap: 0.5rem;
}

.cardItemRightEdit {
    margin-left: auto;
    font-size: 14px;

    display: grid;
    grid-template-columns: 7rem 4.3rem;
    column-gap: 0.2rem;
}

/*Option icon*/
.optionIconButton {
    width: 2rem;
    height: 2rem;

    outline: none;
    border: none;

    background-color: #00000000;
    border-radius: 50%;

    cursor: pointer;
    text-align: center;
    vertical-align: middle;

    transition: 0.2s, smooth;
}

.optionIconButton:hover,
.optionIconButton:focus {
    background-color: rgb(209, 209, 209);
}

.optionIcon {
    width: auto;
    height: 0.8rem;
    margin: auto;
    margin-bottom: 0.1rem;
}

.removeFollowUpQuestionOption{
    display: none;
}


.textarea {
    border-radius: 0.2rem;
    border: 1px solid var(--border);
    height: auto;
    max-height: 8rem;
    min-height: 1rem;
    outline:0px none transparent;

    width: 100%;
    max-width: var(--max-view-width);
    resize: none;
    overflow-y: scroll;

    font-family: 'Segoe UI', system-ui, sans-serif;
    font-size: 16px;
    padding: 0.7rem;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    transition: 0.2s, smooth;
}

.textarea:focus {
    border: 1px solid var(--primary);

}


@media screen and (max-width: 970px) {

    .wrapper {
        width: 90vw;
    }
}



@media screen and (max-width: 700px) {
    .titleWrapper {
        width: 100%;

        display: grid;
        grid-template-columns: auto;
        align-items: center;
    }

    .titleWrapperLeft {
        margin-left: 0;
    }

    .card {
        padding: 1rem;
        border-radius: 5px;
        background-color: var(--primary-background);
        height: fit-content;

        display: grid;
        grid-template-columns: 2rem auto;
        column-gap: 0.5rem;
        row-gap: 0.5rem;

        transition: 0.3, smooth;
    }

    .cardItemRightEdit {
        margin-left: 2.5rem;
        font-size: 14px;

        display: grid;
        grid-template-columns: 7rem 5rem 4.3rem;
        column-gap: 0.2rem;
    }

    .cardItemRight {
        display: none;
    }

    .optionIconButton {
        display: none;
    }

    .removeFollowUpQuestionOption{
        display: block;
    }

    .textarea {
        height: auto;
        max-height: 12rem;
        min-height: 6rem;
    }
}