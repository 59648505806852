.sidebar {
    height: 100vh;
    width: 350px;
    padding: 1rem;

    border-right: 1px solid var(--border);
    padding-top: 3.5rem;

    overflow: hidden;

    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.navigationWrapper{
    max-height: calc(100% - 8rem);
}

.navigationWrapperMinimized{
    max-height: calc(100% - 36rem);
}