.wrapper {
    margin-top: 3rem;
    min-height: 80vh;
}

.li {
    margin-left: 1rem;
    font-size: 15px;
}

.liUnselected {
    margin-left: 1rem;
    font-size: 15px;
    color: var(--border);
}

.grid {
    margin: auto;
    width: fit-content;

    display: grid;
    grid-template-columns: 303px 303px 303px;
    column-gap: 1rem;
    row-gap: 2rem;

    align-items: end;
}

.gridItemHighlighted {
    min-height: 30rem;
}

.gridItem {
    min-height: 27rem;
}

.gridItemTopContent {
    min-height: 19rem;
}

@media screen and (max-width: 960px) {
    .grid {
        grid-template-columns: 303px;
        row-gap: 1rem;
    }

    .gridItem {
        min-height: 0;
    }

    .gridItemTopContent {
        min-height: 0;
    }
}