.wrapper {
    width: calc(600px + 22rem);
}

.titleWrapper {
    width: 100%;

    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
}

.titleWrapperLeft {
    margin-left: auto;
}

.text {
    width: 100%;
    max-width: 600px;
    margin: 0;
    margin-top: 0.2rem;

    font-family: Segoe UI;

    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}