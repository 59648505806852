.listItem {
    padding: 0.5rem;
    margin-bottom: 0.2rem;
    border-radius: 0.2rem;
    align-items: center;

    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;

    background-color: var(--background-hover);
}

.iconWrapper {
    width: 24px;
    height: auto;
    text-align: center;
}

.icon {
    height: 24px;
    width: auto;
}

.right{
    margin-left: auto;
    font-size: 14px;
    color: var(--text-off);
}

.createButton{
    background-color: var(--text-off);
    color: var(--background);
    outline: none;
    border: none;
    cursor: pointer;

    padding: 0.2rem 0.5rem;
    border-radius: 3px;
}

.createButton:hover, .createButton:focus {
    background-color: var(--text);
}

.cancelButton{
    display: block;
    background-color: var(--border);
    outline: none;
    border: none;
    cursor: pointer;

    padding: 0.2rem 0.5rem;
    border-radius: 3px;
}

.cancelButton:hover, .cancelButton:focus {
    background-color: var(--grey);
}

.input{
    font-size: 16px;
    padding: 0.2rem;
    border: 1px solid var(--border);
    outline: none;
}

@media screen and (max-width: 800px) {
    .listItem {
        padding: 0.5rem 0;
        border-radius: 0;
        align-items: center;

        display: flex;
        -webkit-display: flex;
        flex-direction: row;
        column-gap: 1rem;
    }

    .listItem:hover {
        background-color: var(--background);
    }

    .cancelButton{
        display: none;
    }    
}
