.outerWrapper{
    background-color: var(--text);
    width: 100%;
}

.contentWrapper{
    padding: 7rem 5rem;

    width: fit-content;
    margin: auto;
}

.grid{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    column-gap: 10rem;
    row-gap: 3rem;
}

.gridColumn{
    display: grid;
    grid-template-columns: auto;
}

.header{
    color: var(--background);
}

.text{
    color: var(--background);
    font-weight: 300;
    font-size: 14px;
}

.email{
    color: var(--background);
    font-weight: 300;
    font-size: 14px;
    text-decoration: underline;
}

@media screen and (max-width: 800px) {
    .contentWrapper{
        padding: 5rem 1rem;
    }

    .grid{
        grid-template-columns: auto;
    }
}
