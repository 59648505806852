
.checkmark{
    height: 0.75rem;
    width: auto;
}

.actionGroup {
    display: grid;
    grid-template-columns: 8rem 13.5rem auto;
    column-gap: 0.5rem;
}

.submitGrid {
    width: 100%;
    padding: 0.5rem;
    background-color: var(--background);
    bottom: 0;
    left: 0;
    position: fixed;
    border-top: 1px solid var(--border);
}

.submitContent {
    margin: auto;
    width: 16rem;
}

.topButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    align-items: center;
}

.topButtons>label {
    margin-bottom: 0.3rem;
}

.pin{
    margin: 2px;
    padding: 0.25rem 0.5rem 0.25rem 1rem;
    border-radius: 0.25rem;
    background-color: var(--background-hover);
}

.textarea {
    border-radius: 0.2rem;
    border: 1px solid var(--border);
    height: auto;
    max-height: 55vh;
    min-height: 35vh;
    outline:0px none transparent;

    width: 100%;
    max-width: var(--max-view-width);
    resize: none;
    overflow-y: scroll;

    font-family: 'Segoe UI', system-ui, sans-serif;
    font-size: 16px;
    padding: 0.7rem;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    transition: 0.2s, smooth;
}

.textarea:focus {
    border: 1px solid var(--primary);

}

.nameWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.addNewTextTitle {
    margin: 1rem 0;
}

.topBar {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 2rem;
    width: fit-content;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
}

.warningWrapper{
    padding: 0.5rem 1rem;
    border: orange 1px solid;
    background-color: rgb(255, 243, 216);
    border-radius: 5px;
}

.topBarRight {
    display: block;
    margin-left: auto;
    font-size: 14px;
}

.divider {
    padding: 0.8rem 0;
    border-bottom: 1px solid var(--border);
    width: 100%;
}

.buttonGrid {
    width: 100%;

    display: grid;
    grid-template-columns: auto auto;
    row-gap: 1rem;
}

.buttonGridFolderWrapper {
    width: 100%;
    margin-left: "auto";
}

.addTextGrid {
    margin-top: 0rem;

    display: grid;
    grid-template-columns: auto 11rem;
    column-gap: 1rem;
    align-content: center;

    width: fit-content;
}

.addTextButton {
    font-size: 14px;
    font-weight: 500;
    text-align: center;

    user-select: none;
    padding: 0.354rem 1.5rem;
    border-radius: 2rem;
    color: var(--text-light);
    height: fit-content;
    width: 10rem;
    cursor: pointer;

    border: 1px solid var(--primary);
    background-color: var(--primary);
    transition: 0.2s, smooth;
}

.addTextButton:hover {
    background-color: var(--primary-hover);
}

.addTextButtonActive {
    font-size: 14px;
    font-weight: 500;
    text-align: center;

    user-select: none;
    padding: 0.354rem 1.5rem;
    border-radius: 2rem;

    color: var(--primary);
    height: fit-content;
    width: 10rem;
    cursor: pointer;

    border: 1px solid var(--primary);
    background-color: var(--background);
    transition: 0.2s, smooth;
}

.addTextButtonActive:hover {
    background-color: var(--background-hover);
}


.fileListLabelsRight {
    font-size: 14px;
    font-weight: 500;
    margin-top: 0.4rem;
    width: fit-content;
}

.fileReselector {
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    color: var(--text-light);
    height: fit-content;
    width: fit-content;
    cursor: pointer;

    user-select: none;
    background-color: var(--primary);
    transition: 0.2s, smooth;
}

.fileReselector:hover {
    background-color: var(--primary-hover);
}


.fileList {
    display: grid;
    grid-template-columns: auto;
    row-gap: 0.5rem;
    padding-top: 0.3rem;
    margin-top: 1rem;
    border-top: 1px solid var(--border);
}

.fileItem {
    padding: 0.5rem;
    border-radius: 5px;
    background-color: #f5f5f5;
    height: fit-content;

    display: grid;
    grid-template-columns: 3rem auto auto;
    align-items: center;
}

.fileIcon {
    height: 32px;
    width: auto;
}

.fileItemRight {
    margin-left: auto;
    font-size: 14px;

    display: grid;
    grid-template-columns: 4rem 2rem;
    column-gap: 1rem;
    align-items: center;
}

.lineThrough{
    text-decoration: line-through;
}

.sizeLabel {
    display: block;
    text-align: right;
}

.sizeLabelError {
    display: block;
    text-align: right;
    color: var(--error);
}

.optionIconButton {
    width: 2rem;
    height: 2rem;

    outline: none;
    border: none;

    background-color: #00000000;
    border-radius: 50%;

    cursor: pointer;
    text-align: center;
    vertical-align: middle;

    transition: 0.2s, smooth;
}

.optionIconButton:hover,
.optionIconButton:focus {
    background-color: rgb(209, 209, 209);
}

.optionIcon {
    width: auto;
    height: 0.8rem;
    margin: auto;
    margin-bottom: 0.1rem;
}



/*Folder selector*/

.folderSelectionActionWrapper {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-items: center;
    column-gap: 1rem;
    row-gap: 1rem;
}

.listHeader {
    width: fit-content;
    min-width: 50vw;
    display: grid;
    grid-template-columns: auto 7rem;

    padding: 0 0.4rem;

    border-bottom: 1px solid var(--border);
    margin-bottom: 0.5rem;

}

.listHeader * p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.listHeaderButton {
    background-color: var(--background);
    outline: none;
    border: none;

    cursor: pointer;
    text-align: center;

    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px 5px 0 0;

    transition: 0.2s, smooth;
}

.listHeaderButtonMarked {
    background-color: var(--primary);
}

.listHeaderButtonMarked:hover {
    background-color: var(--primary-hover) !important;
}

.listHeaderButtonMarked>p {
    color: var(--text-light);
}

.listHeaderButton:hover {
    background-color: var(--background-hover);
}

.listHeaderIconButton {
    background-color: var(--background);
    outline: none;
    border: none;

    cursor: pointer;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;

    height: 2rem;
    width: 2rem;
}

.listHeaderIconButton:hover {
    background-color: var(--background-hover);
}

.listHeaderIconButtonDisabled {
    background-color: var(--background);
    outline: none;
    border: none;
    color: var(--border);

    cursor: default;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;

    height: 2rem;
    width: 2rem;
}

.listHeaderIconButtonDisabled>img {
    opacity: 0.4;
}

.arrowIcon {
    margin-top: 5px;
    height: auto;
    width: 20px;
}

.warningWrapper{
    padding: 0.5rem 1rem;
    border: orange 1px solid;
    background-color: rgb(255, 243, 216);
    border-radius: 5px;
}


@media screen and (max-width: 800px) {
    .actionGroup {
        grid-template-columns: 7rem 13.5rem;
    }

    .topBarRight {
        display: none;
    }

    .textarea {
        max-height: 55vh;
    }

    .topButtons {
        grid-template-columns: auto;
    }

    /* Modal */
    .folderSelectionActionWrapper {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }

    .buttonGrid {
        grid-template-columns: auto;
    }
}

@media screen and (max-width: 760px) {
    .buttonGridFolderWrapper {
        display: none;
    }

    .nameWrapper {
        grid-template-columns: auto;
    }
}

@media screen and (max-width: 420px) {
    .actionGroup {
        grid-template-columns: 3fr 7fr;
    }

    .fileItemRight {
        grid-template-columns: 2rem;
    }

    .sizeLabel {
        display: none;
    }
}

@media screen and (max-width: 355px) {
    .addTextGrid {
        grid-template-columns: auto;
        row-gap: 1rem;
    }

}