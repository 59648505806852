.listItem {
    padding: 0 0.5rem;
    border-radius: 0.2rem;
    border-bottom: 1px solid var(--background-hover);
    align-items: center;

    display: grid;
    grid-template-columns: auto 2rem;
    column-gap: 1rem;

    transition: 0.2s, smooth;
    cursor: pointer;
}

.listItem:hover {
    background-color: var(--background-hover);
}

.listItemSelected {
    padding: 0 0.5rem;
    border-radius: 0.2rem;
    border-bottom: 1px solid var(--background-hover);
    align-items: center;

    display: grid;
    grid-template-columns: auto 2rem;
    column-gap: 1rem;
    background-color: var(--background-hover);
}

.listItemClickableContent {
    padding: 0.5rem 0;

    display: grid;
    grid-template-columns: 2.5rem auto auto;
    column-gap: 1rem;

    align-items: center;
}

.defaultTextLength {
    width: auto;
    max-width: 75vw;
    display: block;
    overflow-wrap: break-word;

    font-size: 15px;

}

.mobileTextLength {
    width: auto;
    max-width: 75vw;
    display: none;
    overflow-wrap: break-word;
    font-size: 15px;

}

.right {
    margin-left: auto;
}

.date{
    font-size: 14px;
    color: var(--text-off);
    min-width: 5rem;
    text-align: right;
}

.optionWrapper {
    display: block;

    position: relative;
    width: fit-content;
    height: fit-content;
}

.optionIconButton {
    width: 2rem;
    height: 2rem;

    outline: none;
    border: none;

    background-color: #00000000;
    border-radius: 50%;

    cursor: pointer;
    text-align: center;
    vertical-align: middle;

    transition: 0.2s, smooth;
}

.optionIconButton:hover, .optionIconButton:focus {
    background-color: rgb(209, 209, 209);
}

.optionIcon {
    width: auto;
    height: 0.8rem;
}

.optionMenu {
    position: absolute;
    top: 0.5rem;
    right: 0;

    width: fit-content;
    min-width: 8rem;

    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: 5px;

    display: grid;
    grid-template-columns: auto;

    z-index: 100;

    overflow: hidden;
}

.optionMenuItem {
    padding: 0.5rem 1rem;
    border: none;
    border-bottom: 1px solid var(--border);

    text-align: left;
    background-color: var(--background);

    outline: none;
    transition: 0.2s, smooth;
    cursor: pointer;

    font-size: 14px;
}

.optionMenuItem:hover, .optionMenuItem:focus {
    background-color: var(--background-hover);
}

.inputInline {
    display: grid;
    grid-template-columns: 25rem 9rem;
    column-gap: 1rem;
    row-gap: 1rem;
}


.commentNumberPreview{
    display: block;

    height: 2rem;
    width: auto;
    position: relative;
    align-items: center;
}

.commentIcon{
    height: auto;
    width: 29px;
}

.commentNumber{
    position: absolute;
    text-align: center;
    width: 100%;

    top: 1px;
    left: 0;

    font-size: 14px;
    font-weight: 400;
    color: white;

    z-index: 100;
}

.commentNumberPrimary{
    position: absolute;
    text-align: center;
    width: 100%;

    top: 1px;
    left: 0;

    font-size: 14px;
    font-weight: 600;
    color: var(--primary);

    z-index: 100;
}


.errorPreview{
    background-color: rgb(253, 218, 218);
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    display: flex;
    text-align: center;
    justify-content: center;
    justify-items: center;
}

.errorPreviewNumber{
    padding-top: -5px;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-off);
}

.suggestionPreview{
    background-color: rgb(255, 243, 189);
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    display: flex;
    text-align: center;
    justify-content: center;
    justify-items: center;
}

.suggestionPreviewNumber{
    padding-top: -5px;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-off);
}

@media screen and (max-width: 800px) {
    .listItem {
        padding: 0.5rem 0;
        border-radius: 0;
        align-items: center;

        display: grid;
        grid-template-columns: auto 2rem;
        column-gap: 1rem;
    }

    .listItem:hover {
        background-color: var(--background);
    }

    .listItemSelected {
        padding: 0.5rem 0;
        border-radius: 0;
        align-items: center;

        display: grid;
        grid-template-columns: auto 0rem;
        column-gap: 1rem;
        background-color: var(--background-hover);
    }

    .listItemClickableContent {
        padding: 0 0;

        grid-template-columns: 2.5rem auto;
        align-items: baseline;
    }

    .defaultTextLength {
        display: none;
    }

    .mobileTextLength {
        display: block;
    }

    .inputInline {
        display: grid;
        grid-template-columns: auto;
        column-gap: 1rem;
        row-gap: 1rem;
    }

    .optionWrapper {
        display: none;
    }

    .commentNumberPreview{
        display: none;
    }

    .errorPreview{
        display: none;
    }

    .suggestionPreview{
        display: none;
    }
}