.wrapper {
    border-radius: 0.2rem;
    padding: 0.2rem 0.2rem;

    min-width: 2rem;
    height: 1.8rem;

    text-align: center;
}

.colorHuman {
    background: linear-gradient(93.08deg, #38ef7d 0%, #14b3a5 100%);
}

.colorUnsure {
    background: linear-gradient(93.08deg, #FDC830 0%, #f39a35 100%);
}

.colorAI{
    background: linear-gradient(93.08deg, #ED213A 0%, #ca3928 100%);
}

.text {
    margin: auto;
    opacity: 1;
    font-size: 14px;
    font-weight: 600 !important;
}