.wrapper {
    width: 100%;
    position: relative;
}

.wrapperFullpage {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.content {
    width: fit-content;
    min-width: 800px;
    /* max-width: var(--max-view-width); */
    margin: auto;

    height: fit-content;
    min-height: 75vh;
    padding: 5rem 0 8rem 0;
}

.contentFullpage {
    width: fit-content;
    min-width: 800px;
    /* max-width: var(--max-view-width); */

    height: fit-content;
    min-height: 75vh;
}

.grey {
    background-color: var(--grey-background);
}

@media screen and (max-width: 1334px) {
    .wrapperFullpage {
        height: 100%;
    }
}

@media screen and (max-width: 800px) {
    .content {
        width: 90vw;
        min-width: 300px;
    }

    .contentFullpage {
        width: 100vw;
        min-width: 300px;
    }
}