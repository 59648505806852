.infoBox{
    border-radius: 1rem;
    border: 1px solid var(--border);
    background-color: var(--background);

    padding: 0 2rem 0 2rem;

    margin-bottom: 2rem;
    transition: 0.2s, smooth;

    max-width: 45rem;

    display: grid;
    grid-template-columns: auto 2rem;
    column-gap: 2rem;
    align-items: center;
}

.infoBox:hover{
    border: 1px solid var(--primary);
    background-color: var(--primary-background);
}

.infoBox > div {
    padding-bottom: 1rem;
}

.infoBox > div > p {
    display: block;
}

.chevron{
    width: 1.5rem;
    height: auto;
}

.warningWrapper{
    padding: 0.5rem 1rem;
    border: orange 1px solid;
    background-color: rgb(255, 243, 216);
    border-radius: 5px;
}

@media screen and (max-width: 800px) {
    .infoBox{
        padding: 0 1rem 0 1rem;
    
        margin-bottom: 0rem;
    
        display: grid;
        grid-template-columns: auto 2rem;
        column-gap: 2rem;
        align-items: center;
    }

    .infoBox > div {
        padding-bottom: 0rem;
    }

    .infoBox > div > p {
        display: none;
    }

    .chevron{
        width: 1rem;
        height: auto;
    }
}