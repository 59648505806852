:root{
    /* --background: #000;
    --background-2: #000;
    --background-hover: rgb(12, 12, 12);
    --text: #fff;
    --text-off: #e0e0e0;
    --text-light: #fff;
    --text-dark: #000; */

    /* --weight-light: 200;
    --weight-normal: 300;
    --weight-heavy: 400; */

    /* --primary: #0047FF;
    --primary-hover: #030090; */
    --secondary: #EB00FF;
    --secondary-hover: #636363;
    --green-dark: rgb(34, 139, 82);
    --yellow: #EB00FF;
    --red-hover: rgb(197, 0, 0);

    --primary-gradient: linear-gradient(93.08deg, #000000, transparent) #000000;
    --primary-gradient-hover: linear-gradient(93.08deg, #030091 0%, #0047FF 100%);

    --max-view-width: 80rem;

    /* --navbar-bg: #000000;
    --navbar-bg-focus: #161616;
    --border: rgb(29, 29, 29);
    --border-active: rgb(41, 41, 41);  */



    --primary: #228be6;
    --primary-background: #e7f5ff;
    --primary-hover: #1e74be;

    --background: #FFF;
    --background-hover: rgb(240, 241, 241);
    --grey-background: #F9F9F9;
    --grey-dark-background: #f5f5f5;

    --text: #000;
    --text-off: #2B2B2B;
    --text-light: #fff;
    --text-dark: #000;
    --error: rgb(182, 4, 4);
    --link: rgb(0, 0, 177);

    --weight-light: 300;
    --weight-normal: 400;
    --weight-heavy: 500;

    --navbar-bg: #ffffff;
    --navbar-bg-focus: #e6e6e6;
    --border: rgb(216, 216, 216);
    --border-active: rgb(124, 124, 124);
    --grey: rgb(184, 184, 184);
    --greyer: rgb(143, 143, 143);

    --green: #0BB779;
    --red: #E22024;
    --yellow: rgb(255, 174, 52);

    --shadow: 0;
    /* --shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(180, 180, 180, 0.19); */

    /* --green: rgb(38, 163, 94);
    --green-dark: rgb(34, 139, 82);
    --yellow: rgb(255, 207, 63); */


    /* --font: 'Inter';

    --shadow: #c5c5c500;
    --shadow-highlight: #96969600;

    --text-off2: #2f2f2f;
    --text-inverted: #FFF;
    --lighter-grey: rgb(238, 238, 238);
    --light-grey: rgb(223, 223, 223);
    --grey: rgb(184, 184, 184);
    --greyer: rgb(143, 143, 143);
    --grey-dark: rgb(49, 49, 49);

    --blue-link: #e0e0e0;
    --button-conf: #4ff4a2;
    --button-conf-hover: #22cf79;
    --button-text: #FFF;
    --button-text-weight: 400;
    --button-default: rgb(49, 49, 49);
    --button-default-hover: rgb(90, 90, 90);
    --red: #f7532b;
    --red-light: rgb(206, 76, 44);
    --blue: #52b8e7;



    --greyGradient: linear-gradient(to right, rgb(48, 48, 48), var(--background2));
    --greyerGradient: linear-gradient(to right, rgb(59, 59, 59), var(--grey)); */
    
}