.wrapper{
    width: 90%;
    max-width: 300px;
    min-width: 250px;

    margin: auto;

    padding-top: 5rem;
}

.options{
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    row-gap: 2rem;
}